import { Container, Row } from "reactstrap";
// import NumberingWizard from "./NumberingWizard/NumberingWizard";
// import StudentValidationForm from "./StudentValidationForm/StudentValidationForm";
// import VerticalValidationWizard from "./VerticalValidationWizard/VerticalValidationWizard";
// import ShippingForm from "./ShippingForm/ShippingForm";
import Screening from "./ScreeningForm/Screening";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { FormLayout, FormWizard } from "../../../../utils/Constant";
import Header from "../../../../Layout/Header/Header";

const FormWizardOneContainer = () => {
  console.log("FormWizardOneContainer");
  return (
    <>
      <Container fluid>
        <Header />
        <Row>
          <Screening />
          {/* <NumberingWizard />
          <StudentValidationForm />
          <VerticalValidationWizard />
          <ShippingForm /> */}
        </Row>
      </Container>
    </>
  );
};

export default FormWizardOneContainer;
