import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import Store from "./ReduxToolkit/Store";
import "./i18n";
import { unstable_batchedUpdates } from "react-dom";
import { Component } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
interface Props {
  children: React.ReactNode;
}
unstable_batchedUpdates(() => {
  //console.error = () => {};
});

class ErrorBoundary extends Component<Props, { hasError: boolean }> {
  state = { hasError: false };

  componentDidCatch(error: Error) {
    console.error("ErrorBoundary caught an error:", error);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Please try again later.</h1>;
    }
    return this.props.children;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={Store}>
        <GoogleOAuthProvider clientId='106486231081-kvdso6l5mqlkddc6iruuu5absrhviutb.apps.googleusercontent.com'>
          <App />
        </GoogleOAuthProvider>
        {/* <App />4 */}
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
