import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Container, Form, Input, Label, Row } from "reactstrap";
import { Btn, H2, H6, Image, P } from "../../AbstractElements";
import { dynamicImage } from "../../Service";
import {
  CreateAccount,
  DoNotAccount,
  EmailAddress,
  ForgotPassword,
  Href,
  Password,
  RememberPassword,
  SignIn,
  SignInAccount,
  SignInWith,
} from "../../utils/Constant";
import SocialApp from "./SocialApp";
import { GoogleLogin } from "@react-oauth/google";
const Login = () => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("test123@gmail.com");
  const [password, setPassword] = useState("Test@123");
  const navigate = useNavigate();

  const SimpleLoginHandle = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (email === "ken@healthdatamovers.com" && password === "188033c539d2") {
      localStorage.setItem("login", JSON.stringify(true));
      navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
    } else {
      toast.error("Please Enter valid email or password...!");
    }
  };
  const onSuccess = (response: any) => {
    // Handle response here, such as sending the token to your backend
    localStorage.setItem("login", JSON.stringify(true));
    //navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
    const redirectPath =
      localStorage.getItem("redirectAfterLogin") || "/dashboard/default";
    localStorage.removeItem("redirectAfterLogin"); // Clean up
    navigate(redirectPath, { replace: true });
  };

  const onFailure = (error: any) => {
    console.error("Login Failed:", error);
    // Handle error scenarios
  };
  return (
    <Container fluid className='p-0'>
      <Row className='m-0'>
        <Col xs='12' className='p-0'>
          <div className='login-card login-dark'>
            <div>
              <div>
                <Link className='logo text-center' to={Href}>
                  <Image
                    className='img-fluid for-light'
                    src={dynamicImage("logo/logo.png")}
                    alt='logo'
                  />
                  <Image
                    className='img-fluid for-dark'
                    src={dynamicImage("logo/dark-logo.png")}
                    alt='darkLogo'
                  />
                </Link>
              </div>
              <GoogleLogin onSuccess={onSuccess} onError={() => {}} />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
