import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import LayoutRoutes from "./LayoutRoutes";
import Login from "../Component/Authentication/Login";
import { authRoutes } from "./AuthRoutes";
import FormWizardOneContainer from "../Component/Forms/FormsLayout/FormWizardOne/FormWizardOne";
import Default from "../Component/Dashboard/Default/Default";
import CustomRouter from "./CustomRouter";
const RouterData = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Single catch-all route */}
        <Route path='/*' element={<CustomRouter />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterData;
